html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button,
input,
select {
  overflow: visible;
}

button,
input,
select,
textarea {
  margin: 0;
}

button,
select {
  text-transform: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  appearance: field;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  appearance: none;
}

.page-print .logo {
  float: none;
  display: block;
  text-align: left;
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important;
  }
  a,
  a:visited {
    text-decoration: underline !important;
  }
  pre,
  blockquote {
    border: 1px solid #383838;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed;
  }
  .table-wrapper table td {
    width: auto;
  }
  .table-wrapper table > tfoot > tr:first-child {
    border-top: 1px solid #383838;
  }
  .box,
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 1cm;
  }
  .block-content,
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  .block-content {
    page-break-before: avoid;
  }
  .block-title,
  h2,
  h3 {
    page-break-after: avoid;
  }
  .nav-toggle {
    display: none !important;
  }
  .sidebar,
  .nav-sections,
  .header.content > *[class],
  .panel.wrapper > *[class],
  .footer.content > *[class] {
    display: none;
  }
  .logo,
  .footer .copyright {
    display: block !important;
    margin: 10px 0;
  }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto;
  }
  .column.main {
    width: 100% !important;
    float: none !important;
  }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right;
  }
  .footer.content {
    padding: 0;
  }
}